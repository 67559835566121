"use client";
import { useEffect, useMemo, useState } from "react";

import type { Hit } from "@/components/ProductSearch/types";
import { PRODUCT_INDEX_EN } from "@/components/Search/constants";
import getPublicClient from "@/lib/algolia/getPublicClient";
import { ProductType as CourseType } from "@/lib/course/types";
import logger from "@/lib/logger";
import getFilters from "@/components/Search/utils/getFilters";

const getHits = async (
  client: any,
  searchParams: { [key: string]: any },
  productType = "",
) => {
  const params = { ...searchParams };
  if (productType) {
    params.hitsPerPage = 2;
    params.filters = getFilters(productType);
  } else {
    params.filters = getFilters();
  }
  const { hits, queryID } = await client.searchSingleIndex({
    indexName: PRODUCT_INDEX_EN,
    searchParams: params,
  });
  return hits.map((hit: Hit) => ({ ...hit, queryID }));
};

const processAutocompleteHits = (
  execEdHits: Hit[],
  bootCampHits: Hit[],
  generalHits: Hit[],
) => {
  // we need to filter out potential duplicates if some EE or Boot Camps products were returned in both queries
  const execEdIds = execEdHits.map((hit) => hit.uuid);
  const bootCampIds = bootCampHits.map((hit) => hit.uuid);
  const filteredGeneralHits = generalHits.filter(
    (hit) => !execEdIds.includes(hit.uuid) && !bootCampIds.includes(hit.uuid),
  );

  const processedHits = [
    ...execEdHits,
    ...bootCampHits,
    ...filteredGeneralHits,
  ];
  // in case we actually got 2 ee courses and 2 boot camps and 10 other products we now need to make sure we show at
  // max 10 suggestions instead of showing all 14
  return processedHits.slice(0, 10);
};

const handleError = (
  error: Error,
  productType: CourseType.EXECUTIVE_EDUCATION | CourseType.BOOTCAMP | "general",
) => {
  const message = `Failed to fetch ${productType} products for autocomplete`;
  logger.error(message, error);
};

const useAutocomplete = (query: string) => {
  const client = getPublicClient();
  const [execEdHits, setExecEdHits] = useState<Hit[]>([]);
  const [bootCampHits, setBootCampHits] = useState<Hit[]>([]);
  const [generalHits, setGeneralHits] = useState<Hit[]>([]);

  useEffect(() => {
    if (query) {
      const searchParams = {
        query,
        clickAnalytics: true,
        restrictSearchableAttributes: ["title"],
        attributesToRetrieve: [
          "title",
          "marketing_url",
          "subject",
          "partner_keys",
          "uuid",
          "product",
          "program_type",
        ],
        highlightPreTag: `<em class="bg-transparent font-bold not-italic">`,
        highlightPostTag: "</em>",
      };
      const getExecEdHits = async () => {
        const hits = await getHits(
          client,
          searchParams,
          CourseType.EXECUTIVE_EDUCATION,
        );
        setExecEdHits(hits);
      };
      const getBootCampHits = async () => {
        const hits = await getHits(client, searchParams, CourseType.BOOTCAMP);
        setBootCampHits(hits);
      };
      const getGeneralHits = async () => {
        const hits = await getHits(client, searchParams);
        setGeneralHits(hits);
      };
      getExecEdHits().catch((e) =>
        handleError(e, CourseType.EXECUTIVE_EDUCATION),
      );
      getBootCampHits().catch((e) => handleError(e, CourseType.BOOTCAMP));
      getGeneralHits().catch((e) => handleError(e, "general"));
    }
  }, [query, client]);

  const processedHits = useMemo(
    () => processAutocompleteHits(execEdHits, bootCampHits, generalHits),
    [execEdHits, bootCampHits, generalHits],
  );

  return processedHits;
};

export default useAutocomplete;
