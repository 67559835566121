"use client";
import { EmptyQueryBoundary, EmptyQueryHits } from "./EmptyQuery";
import ProductListItem from "./ProductListItem";
import { useProductSearchQuery } from "./ProductSearchContextProvider";
import useAutocomplete from "./hooks/useAutocomplete";
import { Suggestions } from "./types";
import styles from "./index.module.css";

interface ProductSearchSuggestionsProps {
  suggestions: Suggestions;
  sendAnalytics: Function;
}

function ProductSearchSuggestions({
  suggestions,
  sendAnalytics,
}: ProductSearchSuggestionsProps) {
  const { query } = useProductSearchQuery();
  const results = useAutocomplete(query);

  return (
    <EmptyQueryBoundary
      fallback={<EmptyQueryHits suggestions={suggestions} />}
      query={query}
      results={results}
    >
      <div className="bg-white rounded shadow-md overflow-hidden">
        <ul className="list-none p-0 my-0">
          {results.map((result) => (
            <li
              key={result.uuid}
              className={`${styles.searchListItem} m-0 px-3 py-2`}
            >
              <ProductListItem hit={result} />
            </li>
          ))}
        </ul>
        <div className="pl-3 py-2 bg-light">
          <a
            onClick={() => {
              sendAnalytics({ formViewAllButton: true });
            }}
            href={`/search${query?.trim().length ? `?q=${query.trim()}` : ""}`}
          >
            View all results
          </a>
        </div>
      </div>
    </EmptyQueryBoundary>
  );
}

export default ProductSearchSuggestions;
