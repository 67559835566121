"use client";
import Image from "next/image";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import isCourseType from "@/utils/functions/isCourseType";
import { useState } from "react";
import { ProductCardProps } from "@/components/ProductCard/types";

const fallbackCardHeader: Record<string, string> = {
  light: "/product-cards/default-card-header-light.png",
  dark: "/product-cards/default-card-header-light.png",
};

const EDX_LOGO: string = "/trademark-logos/edx-logo-registered-elm.png";

export function ProductCardSSRContent({
  name,
  imageUrl,
  type,
  attributes,
  partnerName,
  partnerLogoUrl,
  childCoursesCount = 0,
  organizationLogoOverride,
}: ProductCardProps) {
  const [imageError, setImageError] = useState(false);
  const theme = isCourseType(type) ? "light" : "dark";
  const cardFallbackImage = fallbackCardHeader[theme];
  const partnerImage = organizationLogoOverride
    ? organizationLogoOverride
    : partnerLogoUrl
      ? partnerLogoUrl
      : EDX_LOGO;
  const partnerArray = Array.isArray(partnerName) ? partnerName : [partnerName];
  return (
    <Card
      className={`${
        theme === "dark" ? "bg-primary" : "bg-white"
      } flex flex-col h-full w-full min-w-[270px]`}
    >
      <CardHeader className="relative rounded-t-xl p-0">
        <Image
          width={270}
          height={104}
          className="m-0 rounded-t-xl object-cover object-top h-[104px] w-auto"
          src={imageError || !imageUrl ? cardFallbackImage : imageUrl}
          alt={name}
          aria-hidden="true"
          onError={() => setImageError(true)}
        />
        {partnerArray?.length === 1 && (
          <Image
            width={116}
            height={66}
            aria-hidden="true"
            className="bg-white shadow absolute p-2 m-0 rounded h-[4.125rem] w-[7.25rem] top-12 left-6 object-scale-down object-center"
            src={partnerImage}
            alt={partnerArray[0] || "Partner Logo"}
          />
        )}
      </CardHeader>
      <CardContent className="mt-7 pb-0">
        <span
          className={`${
            theme === "dark" ? "text-primary-foreground" : ""
          } font-bold text-base m-0 break-words line-clamp-3`}
        >
          {name}
        </span>
        {partnerArray?.map((partner) => (
          <p
            key={partner}
            className={`${
              theme === "dark" ? "text-primary-foreground" : ""
            } text-sm mt-1 mb-0 truncate font-normal`}
          >
            {partner}
          </p>
        ))}
      </CardContent>
      <CardFooter className="flex flex-col flex-grow gap-2 items-start justify-end p-5">
        <Badge variant={theme === "dark" ? "primaryLight" : "default"}>
          {type}
        </Badge>
        {childCoursesCount > 0 && (
          <span
            className={`${theme === "dark" ? "text-primary-foreground" : ""} text-xs pl-2`}
          >{`${childCoursesCount} Courses`}</span>
        )}
      </CardFooter>
    </Card>
  );
}
