"use client";

import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Nav, NavItem } from "@/types/Navigation";
import MobileLinks from "@/components/Navigation/Modal/MobileLinks";
import DesktopLinks from "./DesktopLinks";
type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  navigation: Nav;
};

export default function Modal({ open, onOpenChange, navigation }: Props) {
  const [selectedContent, setSelectedContent] = useState<NavItem | null>(null);
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed top-[76px] inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
          onClick={() => onOpenChange(false)}
        />
        <Dialog.Content
          aria-describedby={undefined}
          className="fixed top-[76px] z-50 inset-y-0 left-0 h-full w-auto border-r bg-background p-0 shadow-lg transition ease-in-out data-[state=open]:slide-in-from-left data-[state=closed]:slide-out-to-left data-[state=closed]:duration-300 data-[state=open]:duration-500"
        >
          <VisuallyHidden.Root asChild>
            <Dialog.Title>main navigation menu</Dialog.Title>
          </VisuallyHidden.Root>
          <div className="flex -mr-1 overflow-auto h-full">
            <div className="hidden md:flex">
              <DesktopLinks
                navigation={navigation}
                setSelectedContent={setSelectedContent}
                selectedContent={selectedContent}
              />
            </div>
            <div className="flex md:hidden w-[100vw] mr-1">
              <MobileLinks
                navigation={navigation}
                setSelectedContent={setSelectedContent}
                selectedContent={selectedContent}
              />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
