import {
  CatalogPageCustomization,
  Footnote,
  Factoid,
  IconImage,
} from "@/types/Customizations";
import { PrunedInstructor } from "@/lib/instructor/types";
import { PartialPartner } from "@/lib/partner/types";
import { CourseDetails } from "@/lib/course/types";
import { string } from "@wordpress/shortcode";

export enum ProgramType {
  BACHELORS = "Bachelors",
  MASTERS = "Masters",
  MICROBACHELORS = "MicroBachelors",
  MICROMASTERS = "MicroMasters",
  PROFESSIONALCERTIFICATE = "Professional Certificate",
  PROFESSIONALPROGRAM = "Professional Program",
  XSERIES = "XSeries",
  DOCTORATE = "Doctorate",
  LICENSE = "License",
  CERTIFICATE = "Certificate",
  NULL = "",
}

export type PriceRange = {
  currency: string;
  min: number;
  max: number;
  total: number;
};

export enum PacingType {
  SELFPACED = "self_paced",
  INSTRUCTORPACED = "instructor_paced",
}

type TaxiForm = {
  // taxiformEntryId?: string | null;
  taxiFormId: string | null;
  formGrouping: string | null;
  title: string | null;
  subtitle: string | null;
  postSubmitUrl: string | null;
};

type OrganizationLogo = {
  title: string | null;
  description: string | null;
  fileUrl: string | null;
  fileFileName: string | null;
  fileContentType: string | null;
  fileSize: number | null;
  fileImageWidth: number | null;
  fileImageHeight: number | null;
};

export type Hero = {
  internalName: string | null;
  subHeading: string | null;
  backgroundColor: string | null;
  organizationLogo?: OrganizationLogo[] | null;
  backgroundImage?: OrganizationLogo[] | null; // Reusing OrganizationLogo type for backgroundImage
  factoids?: Factoid[] | null;
};

type Seo = {
  pageTitle: string | null;
  pageDescription: string | null;
  languageCode: string | null;
};

export type IconModule = {
  icon: IconImage[] | null;
  heading: string | null;
  internalName: string | null;
  statisticLabel: string | null;
  statistic: string | null;
  cta: string | null;
  bodyText: any;
  sortOrder: number;
};

export type ProcessedIconModule = {
  type: string;
  heading: string;
  icon: string;
  statisticLabel: string | undefined;
  statistic: string;
  children: React.ReactNode;
};

export type TextModule = {
  name: string | null;
  title: string | null;
  text: any;
  textAlignment?: string | null;
  ctaPosition?: string | null;
  display?: string | null;
  allowFullWidth: string | null;
  sortOrder: number;
  footNote: Footnote[] | null;
  type: string;
};

export type ProcessedTextModule = {
  type: string;
  header: string;
  display: string;
  description: React.ReactNode;
};

export type MediaModule = {
  internalName: string | null;
  title: string | null;
  bodyRichText: {
    content: Array<{
      data: { target: any };
      content: Array<{
        data: { uri: string | null; target: any };
        marks: Array<any>;
        value: string;
        content: any;
        nodeType: string;
      }>;
      nodeType: string;
    }>;
    nodeType: string;
  };
  media:
    | {
        url: string;
        size: number;
        title: string;
        asset_id: string;
        fileName: string;
        imageWidth: number;
        contentType: string;
        description: string;
        imageHeight: number;
      }[]
    | null;
  [key: string]: any;
};

export type ProcessedMediaModule = {
  type: string;
  header: string;
  mediaUrl: string | null;
  description: string;
};

export type AccordionTextModule = {
  title: string | null;
  titleEmphasis: string | null;
  internalName: string | null;
  accordionItems: AccordionTextItem[] | null;
  usage: string | null;
  theme: string | null;
  introduction: string | null;
  displayEnrollButton: string | null;
  enrollButtonUrlOverride: string | null;
  accordionItemsHeading: string | null;
  textAlignment?: string | null;
  sortOrder: number;
};

export type ProcessedAccordionTextModule = {
  type: string;
  title: string;
  titleEmphasis: string;
  items: {
    name: string;
    question: string;
    answer: React.ReactNode;
  }[];
};

export type ProgramCorporateEndorsement = {
  corporationName: string | null;
  statement: string | null;
  sortValue: number | null;
  image: {
    src: string | null;
    width: number | null;
    height: number | null;
    description: string | null;
  }[];
  individualEndorsements: {
    endorser: {
      uuid: string | null;
      slug: string | null;
      familyName: string | null;
      givenName: string | null;
      title: string | null;
    }[];
    quote: string | null;
    sortValue: number | null;
  }[];
};

export type Program = {
  id: string;
  externalId: string;
  objectID: string;
  title: string;
  subtitle: string;
  /** @deprecated Please use productType instead */
  type: string;
  productType: ProgramType;
  typeAttr: {
    coachingSupported: string;
    created: string;
    logoImage: string;
    modified: string;
    name: string;
    slug: string;
    uuid: string;
  }[];
  status: string;
  marketingUrl: string;
  marketingSlug: string;
  degree: {
    applicationRequirements: string | null;
    applyUrl: string | null;
    bannerBorderColor: string | null;
    campusImage: string | null;
    costsFinePrint: string | null;
    deadlinesFinePrint: string | null;
    displayOnOrgPage: string | null;
    leadCaptureImage: string | null;
    leadCaptureListName: string | null;
    micromastersBackgroundImage: string | null;
    micromastersLongDescription: string | null;
    overallRanking: string | null;
    prerequisiteCoursework: string | null;
    titleBackgroundImage: string | null;
  }[];
  bannerImageUrl: string;
  hidden: boolean;
  created: Date;
  courses: {
    key: string | null;
    title: string | null;
    typeId: number | null;
    uuid: string | null;
    entitlements: {
      mode: string | null;
      price: number | null;
      currency: string | null;
      sku: string | null;
      expires: string | null;
    }[];
  }[];
  partners: PartialPartner[];
  courseDetails?: CourseDetails[];
  /** @deprecated Please use Program.Partners instead */
  authoringOrganizations: {
    description: string | null;
    key: string | null;
    name: string | null;
    slug: string | null;
    uuid: string | null;
  }[];
  cardImageUrl: string;
  marketingHook: string;
  totalHoursOfEffort: number;
  recentEnrollmentCount: number;
  partnerNameOverride: string;
  partnerLogoOverride: string;
  primarySubjectOverride: {
    description: string | null;
    languageCode: string | null;
    masterId: number | null;
    name: string | null;
    subtitle: string | null;
  }[];
  levelTypeOverride: {
    name: string | null;
    sortValue: number | null;
  }[];
  languageOverride: string;
  labels: {
    name: string;
    slug: string;
  }[];
  taxiFormId: string | null;
  programDurationOverride: string;
  lastUpdated: Date;
  excludedFromSearch: boolean;
  excludedFromSeo: boolean;
  hasOfacRestrictions: boolean;
  ofacComment: string;
  overview: string;
  weeksToComplete: number;
  weeksToCompleteMax: number;
  weeksToCompleteMin: number;
  minHoursEffortPerWeek: number;
  maxHoursEffortPerWeek: number;
  video: {
    src: string | null;
  }[];
  expectedLearningItems: {
    value: string;
  }[];
  faq: {
    /** @deprecated Old faq fields */
    // description: string | null;
    // info_url: string | null;
    // name: string | null;
    // type_id: string | null;
    // type_name: string | null;
    question: string | null;
    answer: string | null;
  }[];
  creditBackingOrganizations: {
    description: string | null;
    key: string | null;
    name: string | null;
    slug: string | null;
    uuid: string | null;
  }[];
  corporateEndorsements: ProgramCorporateEndorsement[];
  jobOutlookItems: {
    value: string | null;
  }[];
  individualEndorsements: {
    endorserId: number | null;
    quote: string | null;
  }[];
  creditRedemptionOverview: string;
  enrollmentCount: number;
  programEligibleForOneClickPurchase: boolean;
  curricula: {
    marketingText: string | null;
    marketingTextBrief: string | null;
    name: string | null;
    uuid: string | null;
  }[];
  languages: {
    languageCode: string | null;
  }[];
  transcriptLanguages: {
    languageCode: string | null;
  }[];
  subjectEn: string | null;
  subjectEs: string | null;
  subjects: {
    languageCode: string | null;
    masterId: number | null;
    name: string | null;
    sortValue: number | null;
  }[];
  staff: PrunedInstructor[];
  applicableSeatTypes: {
    name: string;
    slug: string;
  }[];
  instructorOrdering: PrunedInstructor[];
  leadFormUrl: string;
  customizations: {
    contentFlexDetailPage: {
      pageName: string | null;
      pageId: string | null;
      uuid: string | null;
      slugOverride: string | null;
      hero: Hero | null;
      seo: Seo | null;
      taxiForm: TaxiForm | null;
      tabSections:
        | {
            entryId?: string | null;
            internalName?: string | null;
            title?: string | null;
            anchorId?: string | null;
          }[]
        | null;
      modules: Module[] | null;
      ctaButtonText: string | null;
      applyNowButtonUrl: string | null;
    } | null;
    contentDegreeDetailPage: {
      pageName: string | null;
      pageId: string | null;
      uuid: string | null;
      slugOverride: string | null;
      hero: Hero | null;
      seo: Seo | null;
      taxiForm: TaxiForm | null;
      modules: Module[] | null;
    } | null;
    catalogPage: CatalogPageCustomization | null;
  };
  hash: string;
  showOnSiteMapPage: boolean;
  priceRanges: PriceRange[];
  modified: Date;
  creditValue: number | null;
};

export type ProgramPrimarySubjectOverride = {
  description: string | null;
  languageCode: string | null;
  masterId: number | null;
  name: string | null;
  subtitle: string | null;
};

export type ProgramSubject = {
  languageCode: string | null;
  masterId: number | null;
  name: string | null;
  sortValue: number | null;
};

export type Module = {
  textListModule: TextListModule[] | null;
  featuredProductsModule: FeatureProductsModule[] | null;
  prospectusComponentModule: ProspectusComponentModule[] | null;
  // introModule?: IconModule[] | null;
  textModule: TextModule[] | null;
  mediaModule: MediaModule[] | null;
  accordionTextModule: AccordionTextModule[] | null;
  iconModule: IconModule[] | null;
  stickyNavEntryModule: StickyNavEntryModule[] | null;
};

export type Flattened<T> = T & { type: string };

export type FlattenedModule = Flattened<
  | TextListModule
  | FeatureProductsModule
  | ProspectusComponentModule
  | TextModule
  | MediaModule
  | AccordionTextModule
  | IconModule
  | StickyNavEntryModule
>[];

export type ProcessedModule =
  | ProcessedAccordionTextModule
  | ProcessedIconModule
  | ProcessedMediaModule
  | ProcessedTextModule
  | ProcessedTextListModule
  | ProcessedFeatureProductsModule
  | ProcessedProspectusComponentModule
  | ProcessedStickyNavEntryModule;

interface BaseModule {
  entry_id: string;
  created: string;
  modified: string;
  revision: number;
  theme: string | null;
}

export interface TextListModule {
  title: string;
  display: string;
  footNote?: Footnote[] | null;
  textListItems: TextListItem[];
  displayListItemsFirst: string;
  header: string;
  description: RichTextNode;
  leadFormUrl: null;
  sortOrder: number;
}

export interface ProcessedTextListModule {
  type: string;
  header: string;
  description: React.ReactNode;
  items: {
    header: string;
    description: React.ReactNode;
  }[];
}

export interface Icon {
  asset_id: string;
  title: string;
  description: string;
  file_url: string;
  file_contenttype: string;
  file_size: number;
  file_image_width: number;
  file_image_height: number;
}

export interface AccordionTextItem {
  name: string;
  question: string;
  answerRichText: RichTextNode;
  footNote?: Footnote | null;
}

export interface FeatureProductsModule extends BaseModule {
  internalName: string;
  heading: string;
  introduction: string;
  introrichText: RichTextNode;
  productList: ProductList[];
  ctaLabel: string;
  sortOrder: number;
}

export interface ProcessedFeatureProductsModule {
  type: string;
  header: string;
  description: React.ReactNode | string;
  items: {
    header: string;
    description: React.ReactNode;
  }[];
}

interface ProductList {
  title: string;
  display: string;
  theme: string;
  textListItems: TextListItem[];
  displayListItemsFirst: string;
  header: null;
  description: null;
  leadFormUrl: null;
}

export interface StickyNavEntryModule {
  entry_id: string | null;
  linkText: string | null;
  sortOrder: number;
}

export interface ProcessedStickyNavEntryModule {
  type: string;
  id: string;
}

export interface RichTextNode {
  content: RichTextContentNode[];
  nodeType: string;
}

export interface RichTextContentNode {
  content: RichTextContent[];
  nodeType: string;
}

export interface RichTextContent {
  marks: Mark[] | null;
  value: string | null;
  nodeType: string;
  data?: DataClass;
  content: RichTextContent[] | null;
}

interface DataClass {
  target: null;
  uri: string | null;
}

interface Mark {
  type: string;
}

interface TextListItem {
  header: string;
  description: RichTextNode;
  footNote: Footnote[] | null;
  isMainItem: null;
  sortOrder?: number;
}

export interface ProspectusComponentModule {
  componentName: string | null;
  theme: string | null;
  sortOrder: number;
}

export interface ProcessedProspectusComponentModule {
  type: string;
}
