"use client";
import { useEffect, useMemo, useState } from "react";
import CTALink from "@/components/CTA/ctaLink";
import ProductCardList from "@/components/ProductCardList";
import { makeSafeAttributeString } from "@/utils/functions/getIdLabel";
import type { ProductCardListByUUIDClientProps } from "./types";
import { cn } from "@/lib/utils";
import { useLocale } from "next-intl";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import getUserLocation from "@/lib/location/getUserLocationFromClient";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import { useInView } from "react-intersection-observer";

export default function ProductCardListByUUIDClient({
  title = "",
  products,
  location,
  card_format_subtype,
  cta_url,
  cta_label,
  className,
  showAllProducts = false,
  cardWrapperClassName = "",
  product_type: productType,
  initialCardsLength = 4,
  expandText = "",
  contractText = "",
  isMultiCourse = false,
}: ProductCardListByUUIDClientProps) {
  const userLocation = getUserLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useLocale();

  const expandLabel = expandText || (locale === "en" ? "Show More" : "Ver más");
  const contractLabel =
    contractText || (locale === "en" ? "Show Less" : "Ver menos");

  const webElement: ElementClicked["webElement"] = {
    location,
    position: "1",
    variant: translateVariant(productType ?? ""),
    elementType: ElementType.Link,
    htmlId: "show-more-link",
    text: isExpanded ? "Show Less" : "Show More",
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });
  const { ref: showMoreRef, inView: showMoreInView } = useInView(
    DEFAULT_USE_IN_VIEW_OPTIONS,
  );

  useEffect(() => {
    if (showMoreInView) {
      elementViewed();
    }
  }, [showMoreInView, elementViewed]);

  // geotargeting
  const filteredProducts = products.filter((product) => {
    if (userLocation) {
      if (product.allowedIn && product.allowedIn.length > 0) {
        return product.allowedIn.includes(userLocation);
      }
      if (product.blockedIn && product.blockedIn.length > 0) {
        return !product.blockedIn.includes(userLocation);
      }
      return product;
    }
    return product;
  });

  const initialProducts = useMemo(
    () => filteredProducts?.slice(0, initialCardsLength),
    [filteredProducts, initialCardsLength],
  );

  if (!filteredProducts || filteredProducts.length === 0) {
    return null;
  }

  const titleId = makeSafeAttributeString(title);

  const displayedProducts =
    isExpanded || showAllProducts ? products : initialProducts;
  const skipEvents = isExpanded ? initialProducts : [];

  const handleShowButtonClick = () => {
    elementClicked();
    setIsExpanded(!isExpanded);
  };

  const ctaLink = () => (
    <CTALink
      html_id="subject-product-link"
      elementType={ElementType.Entry}
      location={location}
      variant={webElement.variant}
      url={cta_url as string}
      label={cta_label as string}
      radius="none"
      size="lg"
    />
  );

  return (
    <>
      <a className="subnav-item" id={titleId}></a>
      <div className={cn("md:mb-12", cardWrapperClassName)}>
        {title && (
          <h2 className="-mb-1">
            <span className="inline-flex gap-2 items-center justify-center break-words whitespace-normal">
              {title}
            </span>
          </h2>
        )}
        <ProductCardList
          products={displayedProducts}
          shouldScroll={false}
          location={location}
          cardFormatSubType={card_format_subtype}
          className={className}
          skipViewEvents={skipEvents}
          isMultiCourse={isMultiCourse}
        />

        <div className="flex justify-center mt-6 md:mt-5">
          {!showAllProducts &&
          (filteredProducts.length ?? 0) > initialCardsLength ? (
            isExpanded && cta_url && cta_label ? (
              ctaLink()
            ) : (
              <span
                role="button"
                ref={showMoreRef}
                className="underline cursor-pointer"
                onClick={handleShowButtonClick}
              >
                {isExpanded ? contractLabel : expandLabel}
              </span>
            )
          ) : (
            cta_url && cta_label && ctaLink()
          )}
        </div>
      </div>
    </>
  );
}
