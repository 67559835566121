import { Product as ProductType, ProductList } from "@/types/Navigation";
import Image from "next/image";

import CTALink from "@/components/Navigation/LearnContent/ctaLink";
import { useEffect, useMemo } from "react";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
  FormatSubType,
  FormatType,
  NYI_NUMBER,
  ElementClickedWebElement,
} from "@/constants/tagular/main";
import { ProductClicked } from "@/hooks/eventing/types/redventures.ecommerce.v1.ProductClicked";
import useProductEvents from "@/hooks/eventing/useProductEvents";
import {
  EventCacheType,
  getVariantCategoryFromUrl,
  hyphenateForTagular,
  inEventCache,
  setCorrelationId,
} from "@/utils/tagular/helpers";
import { useInView } from "react-intersection-observer";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";

type Props = {
  productLists?: ProductList[] | null;
  label?: string | null;
};

let innerLabel: string = "";
export default function ProductLists({ productLists, label }: Props) {
  if (!productLists || productLists.length === 0) return null;
  innerLabel = label || "";

  return (
    <div
      className={
        productLists.length > 1 ? "grid grid-cols-1 lg:grid-cols-2 gap-6" : ""
      }
    >
      {productLists?.map((list) => {
        const override = (defVal: ElementClickedWebElement) => {
          return {
            ...defVal,
            location: "nav-dropdown",
            htmlId: "design-iteration-a",
            name: hyphenateForTagular(label ?? defVal?.name ?? ""),
            variant: translateVariant(list.title),
            elementType: ElementType.Entry,
          } as ElementClickedWebElement;
        };
        return (
          <div key={list.title} className="w-full">
            <div className="m-0 mb-4 text-base text-primary font-bold">
              {list.title}
            </div>
            <Products products={list.products} title={list.title} />
            <CTALink
              cta={list.cta}
              className="mt-6"
              overrideWebElement={override}
            />
          </div>
        );
      })}
    </div>
  );
}

function Products({
  products,
  title,
}: {
  products?: ProductType[] | null;
  title: string;
}) {
  if (!products || products.length === 0) return null;

  const productsClassName =
    products.length > 1 &&
    [
      "Trending certificates",
      "Trending boot camps",
      "Trending degrees",
    ].includes(title)
      ? "grid grid-cols-1 lg:grid-cols-3 gap-4"
      : "grid grid-cols-1 gap-8";

  return (
    <ul className={productsClassName}>
      {products.map((product, index) => (
        <li
          key={product.id}
          className="col-span-1 divide-y divide-gray-200 border rounded bg-white shadow hover:shadow-lg"
        >
          <ProductLink product={product} index={index} title={title} />
        </li>
      ))}
    </ul>
  );
}

function ProductLink({
  product,
  index,
  title,
}: {
  product: ProductType;
  index: number;
  title?: string;
}) {
  // TODO - ? consolidate product eventing functionality across the code base to remove the complex code duplication

  const { variant, category } = getVariantCategoryFromUrl(product.url);
  const tagularProduct: ProductClicked["product"] = useMemo(
    () => ({
      brand: hyphenateForTagular(product.owner.name),
      formatSubtype: FormatSubType.Grid,
      formatType: FormatType.LongProductCard,
      location: "nav-search",
      name: product.title,
      variant: variant,
      position: index < 0 ? NYI_NUMBER : index + 1,
      category: category,
    }),
    [product.owner.name, product.title, variant, index, category],
  );

  const { productClicked, productViewed, correlationId } = useProductEvents({
    product: tagularProduct,
  });

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setCorrelationId(correlationId);
    productClicked();
  };

  const { ref, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      // statement left commented and in place for possible debugging
      // console.log(`Product viewed: section title: ${title}, name: "${tagularProduct.name}", brand: "${tagularProduct.brand}", variant: "${tagularProduct.variant}", category: "${tagularProduct.category}", url: "${product.url}"`);
      if (
        !inEventCache(EventCacheType.MegaNav, {
          ...tagularProduct,
          label: innerLabel,
        })
      ) {
        productViewed();
      }
    }
  }, [inView, productViewed, tagularProduct]);

  const href = product?.url || "#";
  return (
    <a
      ref={ref}
      key={product.id}
      href={href}
      className="relative flex items-center space-x-3 p-1 h-14 hover:no-underline"
      onClick={handleClick}
    >
      <div>
        <Image
          src={product.owner.logo.url}
          alt={product.owner.logo.altText || ""}
          height={35}
          width={60}
          className="ml-1 h-11 bg-white rounded p-1 border border-putty-light object-scale-down object-center align-middle overflow-clip"
        />
      </div>
      <div className="min-w-0 flex-1 p-2 pl-1">
        <p className="text-xs font-bold color-black mb-1 overflow-hidden text-ellipsis whitespace-nowrap">
          {product.title}
        </p>
        <p className="text-xs text-gray-dark mb-0 overflow-hidden text-ellipsis whitespace-nowrap">
          {product.owner.name}
        </p>
      </div>
    </a>
  );
}
