import getUserLocationFromClient from "@/lib/location/getUserLocationFromClient";
import { PRODUCT_TYPES } from "../constants";

const getFilters = (productType?: string) => {
  const userCountry = getUserLocationFromClient();
  const productTypeFilter = productType
    ? `product:"${productType}"`
    : PRODUCT_TYPES.map((type) => `product:"${type}"`).join(" OR ");

  // geo targeting enabled is just a url param check for geotargeting, is that param ever used?
  // it deafaults to true if it's not present
  // if (geotargetingEnabled && userCountry) {
  if (userCountry) {
    const locationRestrictionFilter = `NOT blocked_in:"${userCountry}" AND (allowed_in:"null" OR allowed_in:"${userCountry}")`;
    return `(${productTypeFilter}) AND ${locationRestrictionFilter}`;
  }
  return productTypeFilter;
};

export default getFilters;
