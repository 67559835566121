import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const ChevronRight = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  return (
    <svg {...svgProps}>
      <path
        d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
        fill="currentColor"
        {...pathProps}
      ></path>
    </svg>
  );
};

export default ChevronRight;
