import Icon from "@/components/icons";

import Image from "next/image";

export default function FooterHeader() {
  return (
    <div className="flex flex-wrap justify-between mb-6 w-full">
      <a href="/" className="mb-6">
        <Image
          src="/trademark-logos/edx-by-2u-white.svg"
          alt="edX homepage"
          title="edX homepage"
          width={127}
          height={67}
          className="optanon-category-C0001"
          data-ot-ignore
        />
      </a>
      <div>
        <div className="flex gap-6 mb-4 items-center justify-center align-middle">
          <a
            className="text-white"
            href="https://www.facebook.com/edxespanol/"
            target="_blank"
            role="button"
            aria-label="Visit our Facebook page"
          >
            <Icon type="facebook" />
          </a>
          <a
            className="text-white"
            href="https://twitter.com/edXOnline"
            target="_blank"
            role="button"
            aria-label="Visit our Twitter page"
          >
            <Icon type="twitter" />
          </a>
          <a
            className="text-white"
            href="https://www.linkedin.com/school/edx/"
            target="_blank"
            role="button"
            aria-label="Visit our LinkedIn page"
          >
            <Icon type="linkedin" />
          </a>
          <a
            className="text-white"
            href="https://www.reddit.com/r/edX/"
            target="_blank"
            role="button"
            aria-label="Visit our Reddit page"
          >
            <Icon type="reddit" />
          </a>
        </div>
      </div>
    </div>
  );
}
