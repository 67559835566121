import type { IconProps } from "./types";
const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const Mail = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  return (
    <svg {...svgProps}>
      <path
        d="M22 4H2v16h20V4Zm-2 4-8 5-8-5V6l8 5 8-5v2Z"
        {...pathProps}
      ></path>
    </svg>
  );
};

export default Mail;
