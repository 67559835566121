import { Pathnames } from "next-intl/routing";

const locales = ["en", "es"] as const;
const defaultLocale = "en";
const localePrefix = "as-needed";
const localeCookie = false;
const localeDetection = false; // cloudflare caches the locale detection-based redirect for everyone - no bueno

const pathnames = {
  "/": "/",
  // Example of adding language specific routes
  // '/about': {
  //   en: '/about',
  //   es: '/acerca'
  // }
} satisfies Pathnames<typeof locales>;

export {
  locales,
  defaultLocale,
  localePrefix,
  localeCookie,
  localeDetection,
  pathnames,
};
