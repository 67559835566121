import {
  CommonVariants,
  ElementType,
  ElementClickedWebElement,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { cn } from "@/lib/utils";
import { CTA } from "@/types";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  cta?: CTA | null;
  className?: string | null;
  overrideWebElement?: (
    defaultValue: ElementClickedWebElement,
  ) => ElementClickedWebElement;
};

export default function CTALink({
  cta,
  className,
  /* Pass through default if not set specifically */
  overrideWebElement = (x) => x,
}: Props) {
  const webElement: ElementClickedWebElement = overrideWebElement({
    elementType:
      cta?.element_type === "entry" ? ElementType.Entry : ElementType.Link, // Default is "LINK"
    htmlId: cta?.html_id,
    location: cta?.location,
    text: cta?.label,
    position: cta?.position ? cta?.position : "1",
    variant: cta?.variant ? cta.variant : CommonVariants.AllProductsMixed,
    ...(cta?.label && {
      name: hyphenateForTagular(cta.label),
    }),
  });

  // is currently only used for the elementClicked event and not elementViewed on purpose
  const { elementClicked } = useElementEvents({ webElement });
  const handleClick = () => {
    elementClicked({ outboundUrl: cta?.url });
  };

  if (!cta) return null;
  return (
    <a
      href={cta?.url}
      className={cn(
        "inline-block cursor-pointer text-link mb-1 hover:underline",
        className,
      )}
      onClick={handleClick}
    >
      {cta?.label}
    </a>
  );
}
