"use client";
import { DefaultProps } from "@/components/TaxiForm/types";
import useTaxiForm from "@/components/TaxiForm/useTaxiForm";
import "./style.css";
import { getCorrelationID, hyphenateForTagular } from "@/utils/tagular/helpers";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import makeEvent from "@/hooks/eventing/makeEvent";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import { EVENTS } from "@/hooks/eventing/types/events";
import { sendTaxiFormEvent } from "@/lib/segment";

export default function DefaultTaxiForm({
  formgrouping,
  formid,
  title,
  subtitle,
  postsubmiturl,
  salt,
  taxiFormData,
  brand,
  productvariant,
  category,
  name,
  segmenteventdata,
}: DefaultProps) {
  const generateCohesionPayload = (action: string) =>
    ({
      correlation: {
        id: getCorrelationID(),
      },
      metadata: {
        category: "Lead",
        action,
        timestamp: new Date().toISOString(),
        productList: [
          {
            brand: hyphenateForTagular(brand ?? ""),
            variant: translateVariant(productvariant ?? ""),
            category: category,
            name: name,
          },
        ],
      },
    }) as ConversionTracked;

  const trackNextStep = () => {
    // This closure ensures that the tracking event only fires on the first press of the 'Next Step' button.
    let hasRun = false;
    return () => {
      if (!hasRun) {
        hasRun = true;
        const payload = generateCohesionPayload("Started");

        const event = makeEvent<ConversionTracked, EVENTS>(payload);
        event<ConversionTracked>(EVENTS.ConversionTracked)();
      }
    };
  };

  const trackSubmitted = () => {
    const payload = generateCohesionPayload("Submitted");

    const event = makeEvent<ConversionTracked, EVENTS>(payload);
    event<ConversionTracked>(EVENTS.ConversionTracked)();

    if (segmenteventdata) {
      sendTaxiFormEvent(segmenteventdata);
    }
  };

  const { TaxiForm, templateIds } = useTaxiForm(
    formgrouping,
    formid,
    postsubmiturl,
    salt,
    taxiFormData,
    {
      nextScreenTransitionSucceeded: trackNextStep(),
      imqSubmissionAttempt: trackSubmitted,
    },
  );

  const {
    titleId,
    introTextId,
    progressBarId,
    errorMountPointId,
    fieldMountPointId,
    nextActionButtonId,
    footerMountPointId,
  } = templateIds;

  return (
    <TaxiForm>
      <div className="u--background-light">
        <div className="card">
          <div className="card__body">
            <div className="form-message">
              <div className="header-wrapper">
                <h2 id={titleId}>{title}</h2>
              </div>
              <p id={introTextId}>{subtitle}</p>
            </div>
            <div className="progressBar" id={progressBarId}>
              <div className="progress__max" hidden>
                <div className="progress__bar"></div>
              </div>
            </div>
          </div>
          <div id={errorMountPointId}>
            <div className="card__body" hidden>
              <div className="u--border-danger u--border-left u--padding-2">
                <div className="h4 u--text-danger"></div>
                <div>
                  <label className="is--invalid"></label>
                  <a className="u--text-danger"></a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="card__body u--border-top">
                <div id={fieldMountPointId}></div>
              </div>
              <div className="card__footer">
                <button
                  id={nextActionButtonId}
                  className="button button--theme-cta button--block"
                  type="button"
                >
                  Next Step
                </button>
                <div id={footerMountPointId}>
                  <div className="u--align-left u--padding-top-2 u--font-size-0875 info-text">
                    <a></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TaxiForm>
  );
}
