const monarchConfig = {
  sourceId: "bdac7c16-924e-4180-bb35-5b2dfff6d308",
  endpoint: "https://beam.edx.org/monarch",
  token: "b43382aa-9f76-406c-a628-bf96086f1c28",
  autoRequest: {
    enabled: false,
    suppressPlacements: true,
    timeout: 2000,
  },
};

export default monarchConfig;
