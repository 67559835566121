"use client";

import { PropsWithChildren, useEffect } from "react";
import {
  LeadFormEventListeners,
  LeadFormPluginOptions,
} from "@/types/LeadFormPlugin";
import type { FormData } from "./types";
import Base64Encode from "@/components/Base64Encode";

function injectTaxiForm(onLoadCallback: () => void) {
  // if we've already loaded the script, don't load it again
  if (window.LeadFormPlugin) {
    onLoadCallback();
    return;
  }

  const script = document.createElement("script");
  script.src = "https://prospect-form-plugin.2u.com/latest/lead.form.plugin.js";
  script.async = true;

  script.onload = onLoadCallback; // Call the callback function when the script has loaded

  document.body.appendChild(script);
}

export default function useTaxiForm(
  formGrouping: string,
  formId: string,
  postSubmitUrl: string,
  salt: string = "___",
  taxiFormData: FormData | null,
  eventListeners?: LeadFormEventListeners,
) {
  const packageFromId = `${salt}-${formGrouping}-${formId}-full-taxi-lead-form-package`;
  const templateFromId = `${salt}-lead-form-template`;
  const mountPointId = `${salt}-taxi-plugin-mount-point`;
  const titleId = `${salt}-title`;
  const introTextId = `${salt}-intro_text`;
  const progressBarId = `${salt}-lead-form-progress-bar-mount-point`;
  const errorMountPointId = `${salt}-lead-form-error-mount-point`;
  const fieldMountPointId = `${salt}-lead-form-field-mount-point`;
  const nextActionButtonId = `${salt}-lead-form-next-action-button`;
  const footerMountPointId = `${salt}-lead-form-footer-mount-point`;

  useEffect(() => {
    injectTaxiForm(() => {
      if (!taxiFormData) return;
      const options: LeadFormPluginOptions = {
        salt: salt,
        thankYouURL: postSubmitUrl,
        packageType: "element",
        packageFrom: packageFromId,
        templateType: "element",
        templateFrom: templateFromId,
        telemetryEnabled: false,
        hostPresetFields: {
          ip: "", // window.visitor?.ip?.address || "0.0.0.0",
          lead_source: "edx",
          // page_url: pageUrlWithEAID,
          eaid: "",
          rv_source: "organic",
          // splash_creative: '', // TODO: figure out how to supply this
        },
      };

      if (eventListeners) {
        options.engineEventListeners = eventListeners;
      }

      const taxiForm = new window.LeadFormPlugin(
        window.document,
        mountPointId,
        options,
      );
      taxiForm.start();
    });
  }, [
    mountPointId,
    packageFromId,
    postSubmitUrl,
    salt,
    templateFromId,
    taxiFormData,
  ]);

  const TaxiForm = ({ children }: PropsWithChildren) => {
    if (!taxiFormData) return null;
    return (
      <section className="taxi-form-section">
        <div className="taxi-form">
          <pre id={packageFromId} className="hidden">
            {JSON.stringify(taxiFormData)}
          </pre>
          <div
            id={mountPointId}
            data-package-type="element"
            data-template-type="element"
          />
        </div>
        <div
          id={templateFromId}
          className="hidden"
          aria-hidden="true"
          data-encoded="base64"
        >
          <Base64Encode>{children}</Base64Encode>
        </div>
      </section>
    );
  };

  return {
    TaxiForm,
    templateIds: {
      titleId,
      introTextId,
      progressBarId,
      errorMountPointId,
      fieldMountPointId,
      nextActionButtonId,
      footerMountPointId,
    },
  };
}
