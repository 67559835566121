import Icon from "@/components/icons";
import { Button, ButtonVariantsType } from "@/components/ui/button";
import { ElementType } from "@/constants/tagular/main";
import React, { LegacyRef } from "react";
import { TagularEventingRequiredProps } from "@/types/Tagular";
import { BeamableComponentAttributes } from "@/components/CohesionReady/eventing/types";
import { fetchBeamableProperties } from "@/components/CohesionReady/eventing/helpers";

export type CTAProps = {
  enable_tracking?: boolean;
  label: string;
  url: string;
  type?: ButtonVariantsType;
  icon?: "launch" | "arrow-right" | "search";
  icon_position?: "left" | "right";
  size?: "default" | "sm" | "lg" | "xl" | "icon";
  radius?: "none" | "default";
  is_external?: boolean;
  target?: string;
  name?: string;
  elementType?: ElementType;
  element_type?: ElementType;
  variant?: string;
  tagular_variant?: string;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  ref?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  className?: string;
} & TagularEventingRequiredProps &
  BeamableComponentAttributes; // can handle both old and new tagular. (respectively)

export function renderCTAMarkup({
  label,
  url,
  type = "default",
  icon,
  icon_position = "right",
  size = "default",
  radius = "default",
  is_external = false,
  target = "_self",
  onClick,
  ref,
  variant,
  className,
  ...props
}: CTAProps) {
  const beamable = fetchBeamableProperties(props);

  let linkEventProps = {};
  let buttonEventProps = {};

  if (beamable && Object.keys(beamable).length) {
    linkEventProps = beamable;
  } else {
    buttonEventProps = { onClick: onClick, ref: ref };
  }

  const content = (
    <span className="inline-flex gap-2 items-center justify-center break-words whitespace-normal">
      {icon && icon_position === "left" && <Icon type={icon} />}
      {label}
      {icon && icon_position === "right" && <Icon type={icon} />}
    </span>
  );

  const link = () => (
    <a href={url} {...linkEventProps} target={target}>
      {content}
    </a>
  );

  if (variant === "link") {
    linkEventProps = {
      onClick,
      ref: ref as LegacyRef<HTMLAnchorElement>,
    };
    return link();
  }

  return (
    <Button
      variant={type}
      radius={radius}
      size={size}
      asChild
      className={`not-prose ${className}`}
      {...buttonEventProps}
    >
      {link()}
    </Button>
  );
}
