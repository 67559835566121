import Primary from "./variants/Primary";
import Secondary from "./variants/Secondary";
import MastersNav from "./variants/Masters";
import FlexNav from "./variants/Flex";
import { NavProps } from "./types";

export default function InternalNav({
  variant = "primary",
  ...props
}: NavProps) {
  switch (variant) {
    case "primary":
      return <Primary {...props} />;
    case "secondary":
      return <Secondary {...props} />;
    case "masters":
      return <MastersNav {...props} />;
    case "flex":
      return <FlexNav {...props} />;
    default:
      return null;
  }
}
