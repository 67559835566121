"use client";
import { Hero as HeroType } from "@/types/Navigation";
import { Button } from "@/components/ui/button";

import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  getHtmlIdNavHero,
  getTagularNameNavHero,
  getTagularVariantNavHero,
} from "@/utils/tagular/helpers";

type Props = {
  hero?: HeroType | null;
  location?: string;
};

export default function Hero({ hero, location }: Props) {
  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    location,
    htmlId: getHtmlIdNavHero(hero?.title),
    position: "1",
    name: getTagularNameNavHero(hero?.title),
    text: hero?.cta?.viewAll?.label,
    variant: getTagularVariantNavHero(hero?.title),
  };
  const { elementViewed, elementClicked } = useElementEvents({ webElement });
  const viewAllUrl = hero?.cta?.viewAll?.url || "/";
  const { ref, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);
  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [elementViewed, inView]);

  const handleClick = () => {
    elementClicked({ outboundUrl: viewAllUrl });
  };

  if (!hero) return null;

  return (
    <div className="bg-primary text-white p-8">
      <div className="flex items-center justify-between w-full gap-4">
        <div className="m-0 grow mb-2 text-[2em]/9 font-bold">{hero.title}</div>
      </div>
      <div className="w-auto max-w-[520px] text-white text-sm mb-4">
        {hero.subTitle}
      </div>
      <div className="flex flex-wrap gap-2 flex-col md:flex-row">
        {hero?.cta?.viewAll && (
          <Button variant="brand" asChild>
            <a
              onClick={handleClick}
              ref={ref}
              href={hero?.cta?.viewAll?.url || "/"}
            >
              {hero?.cta?.viewAll?.label}
            </a>
          </Button>
        )}
        {hero?.cta?.learnMore && (
          <Button variant="brandOutline" asChild>
            <a href={hero?.cta?.learnMore?.url || "/"}>
              {hero?.cta?.learnMore?.label}
            </a>
          </Button>
        )}
      </div>
    </div>
  );
}
