import * as Accordion from "@radix-ui/react-accordion";
import { useRefinementList } from "react-instantsearch";
import {
  FACET_FETCH_LIMIT,
  FILTER_CATEGORIES_NAMES_MAP,
  LEARNING_TYPE_FILTER_SUBTITLE,
  BOOT_CAMP,
} from "../constants";
import useHandleSearchParams from "@/hooks/useHandleSearchParams";
import getSortedRefinementItems from "../utils/getSortedRefinementItems";
import { useState } from "react";
import SearchIcon from "@/components/icons/Search";
import CloseIcon from "@/components/icons/Close";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { useSearchResultCount } from "../SearchContextProvider";

const ModalAccordionItem = ({ filterCategory }: { filterCategory: string }) => {
  const { setResultCount } = useSearchResultCount();
  const { setSearchParam, removeSearchParam } = useHandleSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const { items, refine } = useRefinementList({
    attribute: filterCategory,
    sortBy: ["count:desc"],
    limit: FACET_FETCH_LIMIT,
    showMore: false,
    escapeFacetValues: true,
  });

  const handleRefinementClick = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setResultCount(0);
    refine(value);

    if (event.target.checked) {
      setSearchParam(filterCategory, value);
    } else {
      removeSearchParam(filterCategory, value);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const filteredItems = getSortedRefinementItems(items).filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <Accordion.Item value={filterCategory} className="border-b border-gray-200">
      <Accordion.Header className="flex m-0">
        <Accordion.Trigger
          className={cn(
            "bg-[#fbfaf9] md:bg-putty-200 flex flex-1 py-2 px-5 mt-2 items-center justify-between text-lg text-left font-bold transition-all [&[data-state=open]>svg]:rotate-180",
            "bg-transparent pt-0 font-normal text-[1.125rem]",
          )}
        >
          {FILTER_CATEGORIES_NAMES_MAP[filterCategory]}
          <ChevronDownIcon
            className={cn(
              "h-5 w-5 shrink-0 text-brand font-bold transition-transform duration-200",
              "text-gray-700 color-gray-700",
            )}
          />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content
        className={cn(
          "overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
          "relative -mx-5",
        )}
      >
        <div className="px-5">
          <div className="w-full mb-3 relative">
            <input
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="select-none w-full px-5 py-3 border outline-none focus:outline-none focus:border-gray-200 text-gray-600 border-gray-200 text-base pr-12"
            />
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400">
              {searchQuery ? (
                <button
                  onClick={handleClearSearch}
                  className="hover:text-gray-600"
                >
                  <CloseIcon />
                </button>
              ) : (
                <SearchIcon />
              )}
            </div>
          </div>

          {filteredItems.length > 0 ? (
            filteredItems
              .filter((item) => item.label !== BOOT_CAMP)
              .map((item) => (
                <div key={item.label} className="py-1 px-5">
                  <div className="py-1 flex justify-start items-start">
                    <input
                      type="checkbox"
                      id={item.value}
                      value={item.value}
                      checked={item.isRefined}
                      onChange={handleRefinementClick}
                      className="border-2 mr-2 border-gray-700 min-w-4 h-5 w-5 cursor-pointer"
                    />
                    <div className="flex justify-start items-start flex-col">
                      <label
                        htmlFor={item.value}
                        className="text-gray-700 flex items-center mb-0 leading-5"
                      >
                        {item.label}
                      </label>
                      <label className="text-xs text-gray-700">
                        {filterCategory === "learning_type" &&
                          LEARNING_TYPE_FILTER_SUBTITLE[
                            item.value as keyof typeof LEARNING_TYPE_FILTER_SUBTITLE
                          ]}
                      </label>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="pb-3 px-5 text-gray-600">No options found</div>
          )}
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
};

export default ModalAccordionItem;
