"use client";
import * as React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import logger from "@/lib/logger";
import styles from "./index.module.css";
import { uppercaseBreadcrumbAbbreviations } from "@/utils/functions/formatting";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import decodeMalformedText from "@/utils/decodeMalformedText";

interface BreadcrumbsProps {
  variant?: string;
  path: string;
  pageName?: string;
  linksToShowOnSmallScreen?: number;
  title?: string;
  isExecEd?: boolean;
}

export default function Breadcrumbs({
  variant = "light",
  path,
  pageName,
  linksToShowOnSmallScreen,
  title,
  isExecEd = false,
}: BreadcrumbsProps) {
  if (typeof path !== "string") {
    logger.error("Invalid prop: path should be a string");
    return null;
  }
  // Split the path
  let pathItems = path.split("/").filter(Boolean);
  pathItems = pathItems.map((item) => item.replace(/-/g, " "));
  pathItems = uppercaseBreadcrumbAbbreviations(pathItems);
  if (pageName) {
    pathItems[pathItems.length - 1] = decodeMalformedText(pageName);
  }

  const isLightVariant = variant === "light";

  const totalLinks = pathItems.length;

  const hideHomeLink = isExecEd
    ? linksToShowOnSmallScreen && totalLinks - 1 === linksToShowOnSmallScreen
    : linksToShowOnSmallScreen && totalLinks - 1 > linksToShowOnSmallScreen;

  if (isExecEd && title) {
    pathItems[pathItems.length - 1] = title;
  }

  return (
    <Breadcrumb className="not-prose mb-4">
      <BreadcrumbList
        className={isExecEd ? "flex-nowrap sm:flex-wrap text-sm" : ""}
      >
        <BreadcrumbItem
          className={`
            ${hideHomeLink ? "sm:inline-flex hidden" : ""}
            ${
              isLightVariant
                ? `${styles.breadcrumbs} text-primary-foreground capitalize`
                : `${styles.breadcrumbs_dark} capitalize`
            }
            `}
        >
          <BreadcrumbLink
            className={
              isLightVariant
                ? styles.breadcrumb_link
                : styles.breadcrumb_link_dark
            }
            href="/"
          >
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        {pathItems.map((component, index) => {
          const isLastItem = index === pathItems.length - 1;
          // If linksToShowOnSmallScreen is passed, only show the specified number of links starting from the bottom of the list
          const isLinkVisibleOnMobile = isExecEd
            ? isLastItem
            : linksToShowOnSmallScreen
              ? index >= totalLinks - linksToShowOnSmallScreen - 1 &&
                index < totalLinks - 1
              : true;
          return (
            <React.Fragment key={index}>
              {linksToShowOnSmallScreen ? (
                <BreadcrumbSeparator
                  variant={variant}
                  className={!isLinkVisibleOnMobile ? "hidden sm:inline" : ""}
                >
                  <ChevronRightIcon
                    className="hidden sm:block text-white font-bold w-4 h-4 "
                    style={{
                      strokeWidth: 1,
                      stroke: variant === "light" ? "white" : "#00262b",
                    }}
                  />
                  <ChevronLeftIcon
                    className="block sm:hidden text-white font-bold w-4 h-4 "
                    style={{
                      strokeWidth: 1,
                      stroke: variant === "light" ? "white" : "#00262b",
                    }}
                  />
                </BreadcrumbSeparator>
              ) : (
                <BreadcrumbSeparator variant={variant} />
              )}

              <BreadcrumbItem
                className={`
                  ${!isLinkVisibleOnMobile ? "sm:inline-flex hidden" : ""}
                  ${
                    isLightVariant
                      ? `${styles.breadcrumbs} text-primary-foreground capitalize`
                      : `${styles.breadcrumbs_dark} capitalize`
                  }
                  `}
                aria-current={pathItems.length === index + 1 ? "page" : false}
              >
                {index === pathItems.length - 1 ? (
                  <span className={!isLightVariant ? "opacity-70" : ""}>
                    {component}
                  </span>
                ) : (
                  <BreadcrumbLink
                    className={
                      isLightVariant
                        ? styles.breadcrumb_link
                        : styles.breadcrumb_link_dark
                    }
                    href={`/${pathItems
                      .slice(0, index + 1)
                      .join("/")
                      .replaceAll(" ", "-")
                      .toLowerCase()}`}
                  >
                    {component}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
