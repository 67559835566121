"use client";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { ExpandableBlockProps } from "../types";

export default function ExpandableBlock({
  heading = "",
  maxHeight = 200,
  blur = false,
  children,
}: ExpandableBlockProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState<number>(maxHeight);
  const shouldShowMore = height > maxHeight;

  useEffect(() => {
    function handleResize() {
      if (containerRef?.current !== null) {
        setHeight(containerRef?.current?.offsetHeight);
      }
    }

    handleResize();
    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener("resize", debouncedHandleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div className="mx-auto bg-white">
      {heading && (
        <p className="text-xl mb-3 text-primary font-bold">{heading}</p>
      )}
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out relative ${isExpanded ? "max-h-fit" : ""}`}
        style={!isExpanded ? { maxHeight: `${maxHeight}px` } : {}}
      >
        <div className="h-100" ref={containerRef}>
          {children}
        </div>
        {blur && shouldShowMore && !isExpanded && (
          <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-white via-white/80 to-transparent pointer-events-none"></div>
        )}
      </div>
      {shouldShowMore && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-black-100 underline"
        >
          {isExpanded ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
}
