"use client";
import React, { useEffect, useState } from "react";

import ArrowRight from "@/components/icons/ArrowRight";
import styles from "../Banner.module.css";
import type { CourseHubProps, Hub } from "../types";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import {
  CommonVariants,
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { useInView } from "react-intersection-observer";

const defaultHubs = [
  {
    title: "Executive Education",
    subTitle:
      "Expert-led, fully supported courses that build career-critical skills",
    link: "/executive-education",
    variant: "certificates-exec-ed",
  },
  {
    title: "Master's Degrees",
    subTitle: "Online degree programs from top universities",
    link: "/masters",
    variant: "degrees-masters",
  },
  {
    title: "Bachelor's Degrees",
    subTitle: "Begin or complete a degree; fully online",
    link: "/bachelors",
    variant: "degrees-bachelors",
  },
];

const HubLink = (theme: string, hub: Hub, i: number) => {
  const webElement: ElementClicked["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: "product-description-link",
    location: "learning-opportunities",
    position: String(i + 1),
    text: hub.subTitle || "",
    name: hub.title,
    variant: hub.variant,
  };

  const outboundUrl = hub.link;

  const { elementClicked } = useElementEvents({
    webElement,
  });

  const handleClick = () => {
    elementClicked({ outboundUrl });
  };

  return (
    <li
      onClick={handleClick}
      className={`mb-12 relative ${
        theme === "primary" ? styles.primaryHub : ""
      }`}
      key={i}
    >
      <a href={hub.link}>
        <span
          className={
            theme === "primary"
              ? "inline bg-accent-yellow text-primary px-2 py-1 text-xs rounded"
              : "inline bg-primary text-primary-foreground px-2 py-1 text-xs rounded"
          }
        >
          New
        </span>
        <h3 className="text-primary-foreground lg:text-3xl mt-1 mb-4">
          {hub.title}
        </h3>
        <span className="text-primary-foreground text-base">
          {hub.subTitle}
        </span>

        <span className={styles.arrow}>
          <ArrowRight />
        </span>
      </a>
    </li>
  );
};

const CourseHub = ({
  theme = "primary",
  subTitle = "We've added 500+ learning opportunities to create one of the world's most comprehensive free-to-degree online learning platforms.",
  hubs = defaultHubs,
}: CourseHubProps) => {
  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: "product-description-link",
    location: "opportunities-to-learn",
    position: "1",
    text: "More opportunities for you to learn",
    name: "all",
    variant: CommonVariants.AllProductsMixed,
  };

  const { elementViewed } = useElementEvents({
    webElement,
  });

  const { ref, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) elementViewed();
  }, [inView, elementViewed]);

  return (
    <div
      ref={ref}
      className={`fullwidth max-w-none py-12 md:py-16 not-prose ${
        theme === "primary" ? "bg-primary" : "bg-primary-gradient"
      }`}
    >
      <div className="container">
        <h2
          className={`text-4xl md:text-6xl my-5 text-white ${
            theme === "primary" ? "" : "italic"
          }`}
        >
          <span
            className={
              theme === "primary"
                ? "text-accent-blue"
                : "text-primary-foreground"
            }
          >
            More opportunities
          </span>{" "}
          for you to learn
        </h2>
        <p
          className={`text-lg leading-9 ${
            theme === "gradient" ? "text-white" : "text-putty-light"
          }`}
        >
          {subTitle}
        </p>
        <ul
          className={`block md:grid md:grid-cols-2 md:gap-x-[100px] mx-auto lg:grid-cols-${hubs.length}`}
        >
          <ClientOnly>
            {hubs.map((hub, i) => HubLink(theme, hub, i))}
          </ClientOnly>
        </ul>
      </div>
    </div>
  );
};

export default CourseHub;
