import { defineRouting } from "next-intl/routing";
import { createNavigation } from "next-intl/navigation";
import {
  locales,
  defaultLocale,
  localePrefix,
  localeCookie,
  localeDetection,
} from "@/i18n/config";

export const routing = defineRouting({
  locales,
  defaultLocale,
  localePrefix,
  localeCookie,
  localeDetection,
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter } =
  createNavigation(routing);
