"use client";
import { useState, useEffect } from "react";
import Image from "next/image";

import { Button } from "@/components/ui/button";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { Nav } from "@/types/Navigation";
import ProductSearch from "@/components/ProductSearch";
import getUserInfo from "@/lib/auth/getUserInfo";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import UserMenu from "../UserMenu";
import { ActiveBanner } from "../types";
import SitewideBanner from "@/components/SitewideBanner";
import { ProcessedCourse } from "@/lib/course/types";

type Props = {
  navigation: Nav;
  openNav: boolean;
  setOpenNav: Function;
  activeBanner?: ActiveBanner;
  course?: ProcessedCourse;
};

export default function DesktopNav({
  navigation,
  openNav,
  setOpenNav,
  activeBanner,
  course,
}: Props) {
  const { header } = navigation;
  const userInfo = getUserInfo();
  const [userLoggedIn, setUserLoggedIn] = useState<boolean | null>(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  useEffect(() => {
    setUserLoggedIn(isUserLoggedIn());
  }, []);

  const handleMouseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenNav(!openNav);
  };

  const handleMouseOver = () => {
    if (!mouseEntered) {
      setOpenNav(true);
    }
    setMouseEntered(true);
  };

  const handleMouseOut = () => {
    setMouseEntered(false);
  };

  return (
    <>
      <div className="hidden md:flex flex-col w-full border-b border-putty-400">
        <div className="relative h-[76px] flex justify-between items-center">
          {/* Left */}
          <div className="flex items-center gap-6 w-full pl-2 sm:pl-4">
            <a href="/" className="min-w-12">
              <Image
                height={60}
                width={60}
                src={header.logo.url}
                alt={"edX Online Homepage"}
                title="edX"
                className="optanon-category-C0001"
                data-ot-ignore
              />
            </a>
            <div className="flex items-center gap-2 w-full">
              <Button
                size="lg"
                onClick={handleMouseClick}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseOut}
              >
                {header.navButton?.label}{" "}
                {openNav ? (
                  <ChevronUpIcon className="h-4 w-4 ml-2 font-bold" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 ml-2 font-bold" />
                )}
              </Button>
              <ProductSearch
                course={course}
                variant="nav-header"
                placeholder={header.search?.placeholder || "Search"}
                embedded={false}
                isSearchBoxColumn={false}
              />
            </div>
          </div>
          {/* Right */}
          <div className="flex items-center pr-2 sm:pr-4">
            <Button variant="ghost" className="px-3" asChild>
              <a href={header.cta?.business?.url}>
                {header.cta?.business?.label}
              </a>
            </Button>
            {userLoggedIn === null ? (
              <></>
            ) : userLoggedIn && userInfo ? (
              <UserMenu
                headerUrls={userInfo.headerUrls}
                userImage={userInfo.userImageUrls?.medium}
              />
            ) : (
              <>
                <Button variant="ghost" asChild className="px-3 mr-2">
                  <a href={header.cta?.signIn?.url}>
                    {header.cta?.signIn?.label}
                  </a>
                </Button>
                <Button variant="brand" asChild>
                  <a href={header.cta?.register?.url}>
                    {header.cta?.register?.label}
                  </a>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Banner */}
      {activeBanner && (
        <div className="hidden md:block absolute w-full z-40">
          <SitewideBanner {...activeBanner} />
        </div>
      )}
    </>
  );
}
