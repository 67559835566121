import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary-hover hover:text-primary-hover-foreground border-2 border-primary",
        primaryOutline:
          "text-primary shadow border-2 border-primary hover:bg-primary hover:text-white",
        lightOutline:
          "text-primary bg-putty-light shadow border-2 border-primary hover:bg-primary hover:text-white",
        brand:
          "bg-brand text-brand-foreground shadow border-2 border-brand hover:bg-white hover:text-brand",
        brandOutline:
          "bg-white text-brand shadow border-2 border-brand hover:bg-brand hover:text-white",
        ghost:
          "text-gray-dark hover:bg-putty-light hover:text-accent-foreground",
        ghostPrimary: "text-primary hover:bg-primary hover:text-white",
        destructive:
          "bg-danger text-danger-foreground shadow-sm hover:bg-danger-dark",
        outline:
          "border border-input bg-background shadow-sm hover:bg-putty-light hover:text-black",
        secondary:
          "bg-secondary border-2 border-secondary text-secondary-foreground shadow-sm hover:bg-secondary-hover hover:text-secondary-hover-foreground",
        light: "hover:bg-putty-light hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        bordered: "@apply border-[1px] rounded-full",
        white:
          "bg-white text-gray-dark hover:bg-primary-hover hover:text-white",
        inversePrimary:
          "absolute left-[32%] top-1/2 transform -translate-x-[25%] -translate-y-1/2 xl:left-1/2",
      },
      size: {
        default: "h-auto px-4 py-2",
        sm: "h-auto px-3 text-xs border",
        lg: "h-auto lg:h-10 px-4 text-base",
        xl: "h-auto px-4 py-2 text-base whitespace-normal",
        icon: "h-9 w-9",
      },
      radius: {
        default: "",
        none: "rounded-none",
        sm: "rounded",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      radius: "default",
    },
  },
);

export type ButtonVariantsType =
  | "default"
  | "primaryOutline"
  | "lightOutline"
  | "brand"
  | "brandOutline"
  | "ghost"
  | "ghostPrimary"
  | "destructive"
  | "outline"
  | "secondary"
  | "light"
  | "link"
  | "bordered"
  | "white";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, radius, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, radius, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
